import React from 'react';
import { Link, navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { ReactComponent as LogoWeb } from '../../images/mrman-trailer-trash-boys-site-logo.svg';
import { ReactComponent as LogoMobile } from '../../images/mrman-trailer-trash-boys-site-logo-mobile.svg';

import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { routes } from '../../services/navigation/navigation.service.routes';

import './ApplicationLogo.scss';

const ApplicationLogo = ({ notLink }) => {
  const onClick = () => {
    navigate(routes.root);
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
  };

  const Elem = notLink ? 'span' : Link;
  const props = {
    className: 'ApplicationLogo'
  };

  if (!notLink) {
    props.onClick = onClick;
    props.to = routes.root;
    props['aria-label'] = 'Site logo';
  }

  return (
    <Elem {...props}>
      <LogoWeb className="DesktopIcon" />
      <LogoMobile className="MobileIcon" />
    </Elem>
  );
};

ApplicationLogo.propTypes = {
  notLink: PropTypes.bool,
};

export default ApplicationLogo;
