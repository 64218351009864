import { removeSpecialCharacters } from '../util-service/util.service';
import { ROUTES } from './navigation.routes';
import { urlSearchParamsPlaceholder } from '../../constants/navigation';

export const routes = ROUTES;

export const getName = (name) => {
  if (name) {
    name = removeSpecialCharacters(name);
    name = name.replace(/[^A-Za-z0-9]/g, '-');
  }
  return name;
};

export const getDirectorDetailsPageRoute = (id, name) => `/${routes.director}/${id}/${getName(name)}`;

export const getMovieDetailsPageRoute = (movieId, movieName) => {
  if (!movieName) return;
  let name = getName(movieName);
  return `/${routes.movie}/${movieId}/${name}`;
};

export const getSceneDetailsPageRoute = (movieId, movieName, sceneId) => {
  if (!movieName) return;
  let name = getName(movieName);
  return `/${routes.movie}/${movieId}/${name}/scene/${sceneId}`;
};

export const getMostWatchedPageRoute = () => routes.mostWatched;

export const getContentQueryPageRoute = (content, page, sort) => `?content=${content}&sort=${sort}&page=${page}`;

export const getOriginalsScenesPageRoute = (page, sortBy) =>
  `/${routes.originals}${getContentQueryPageRoute('Scenes', page, sortBy)}`;

export const getStarDetailsRoute = (id, name) => `${routes.stars}/${id}/${getName(name)}`;

export const getStudioDetailsRoute = (id, name) =>
  `/${routes.studios}/${id}/${getName(name)}?${urlSearchParamsPlaceholder}`;

export const getThemeDetailsRoute = (id, name) => `/${routes.theme}/${getName(name)}`;

export const getSexActDetailsRoute = (id, name) => `/${routes.sexAct}/${getName(name)}`;

export const getMoviePlaylistRoute = (moviePlaylistId, name) => {
  if (name) {
    name = removeSpecialCharacters(name);
  }
  return `${routes.playlists}/${moviePlaylistId}/movies/${name}`;
};

export const getScenePlaylistRoute = (scenePlaylistId, name) => {
  if (name) {
    name = removeSpecialCharacters(name);
  }
  return `${routes.playlists}/${scenePlaylistId}/scenes/${name}`;
};

export const getFavoritesPageRoute = () => routes.favorites;

export const getStreamBlockedRoute = () => `/${routes.streamblocked}`;

export const getSignInPageRoute = () => `/${routes.signin}`;

export const getJustAddedPageRoute = () => routes.justAdded;

export const getStarScenesRoute = (starId, starName, page, sortBy) =>
  `${getStarDetailsRoute(starId, starName)}?content=Scenes&sort=${sortBy}&page=${page}`;